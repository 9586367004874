'use client';

import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useDeployRefreshManager } from '@qb/frontend/hooks/useDeployRefreshManager';
import { isApiBusinessLogicError } from '@qb/httpRequest/utils';
import { RightDrawerContextProvider } from '@/global/stores/RightDrawerContext/RightDrawerContext';
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      /** Don't retry queries on 404 */
      retry: (failureCount, error: unknown) => {
        // Don't retry on business logic errors.
        if (isApiBusinessLogicError(error)) {
          return false;
        }

        // default retry is 3 times only.
        return failureCount < 3;
      }
    }
  }
});
export const AllTenantsProviders = ({
  children
}: PropsWithChildren) => {
  useDeployRefreshManager();
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="AllTenantsProviders" data-sentry-source-file="AllTenantsProviders.tsx">
      <RightDrawerContextProvider data-sentry-element="RightDrawerContextProvider" data-sentry-source-file="AllTenantsProviders.tsx">{children}</RightDrawerContextProvider>
      <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="AllTenantsProviders.tsx" />
    </QueryClientProvider>;
};