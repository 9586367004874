'use client';

import React, { ReactNode, useState, PropsWithChildren, useContext } from 'react';
import noop from 'lodash/noop';
import { SxProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { RightDrawer } from '@/global/stores/RightDrawerContext/RightDrawer';
type ContextType = {
  setActiveDrawer: (component: ReactNode, drawerPaperSx?: SystemStyleObject, gridContainerSx?: SxProps) => void;
  activeDrawer: ReactNode | undefined;
  isOpen: boolean;
  drawerPaperSx: SystemStyleObject;
  gridContainerSx: SxProps;
  hideDrawer: () => void;
};
export const RightDrawerContext = React.createContext<ContextType>({
  isOpen: false,
  hideDrawer: noop,
  setActiveDrawer: noop,
  drawerPaperSx: {},
  gridContainerSx: {},
  activeDrawer: null
});
export const RightDrawerContextProvider = ({
  children
}: PropsWithChildren) => {
  const [activeDrawerState, setActiveDrawerState] = useState<ReactNode>(null);
  const [drawerPaperSx, setDrawerPaperSx] = useState<SystemStyleObject>({});
  const [gridContainerSx, setGridContainerSx] = useState<SxProps>({});
  const [isOpen, setIsOpen] = useState(false);
  const hideDrawer = () => {
    setIsOpen(false);
  };
  function setActiveDrawer(activeDrawer: ReactNode, drawerPaperSxParam: SystemStyleObject = {}, gridContainerSxParam: SxProps = {}) {
    setActiveDrawerState(activeDrawer);
    setIsOpen(true);
    setDrawerPaperSx(drawerPaperSxParam);
    setGridContainerSx(gridContainerSxParam);
  }
  return <RightDrawerContext.Provider value={{
    setActiveDrawer,
    activeDrawer: activeDrawerState,
    isOpen,
    drawerPaperSx,
    gridContainerSx,
    hideDrawer
  }} data-sentry-element="unknown" data-sentry-component="RightDrawerContextProvider" data-sentry-source-file="RightDrawerContext.tsx">
      {children}
    </RightDrawerContext.Provider>;
};
export const useRightDrawerContext = () => {
  const context = useContext(RightDrawerContext);
  if (context === undefined) {
    throw new Error('useRightDrawer must be used within a useRightDrawerProvider');
  }
  return context;
};
export const RightDrawerWrapper = () => {
  const {
    activeDrawer,
    isOpen,
    hideDrawer,
    drawerPaperSx,
    gridContainerSx
  } = useRightDrawerContext();
  if (!activeDrawer) {
    return null;
  }
  return <RightDrawer isOpen={isOpen} onClose={hideDrawer} drawerPaperSx={drawerPaperSx} gridContainerSx={gridContainerSx} data-sentry-element="RightDrawer" data-sentry-component="RightDrawerWrapper" data-sentry-source-file="RightDrawerContext.tsx">
      {activeDrawer}
    </RightDrawer>;
};